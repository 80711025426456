import React from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import comingSoonImg from "../../assets/images/icons/coming-soon.png";
import SEO from './../SEO/SEO';
import Tada from 'react-reveal/Tada';

const ComingSoon = () => {

    const { t } = useTranslation();

    return (
        <div className='not-found d-flex align-items-center justify-content-center text-center'>
            <SEO
                title={t('general_words.coming_soon') + ' | ' + t('about_us.main_desc').substring(0, 20)}
                description={t('about_us.main_desc').substring(0, 200)}
                name={t('general_words.lojiatech')}
                type='article'
                image={window.location.host + require('../../assets/images/icons/lojiatech-logo.png')}
                keywords={t('general_words.smart_home_system') + ',' + t('general_words.smart_building_systems') + ',' + t('general_words.light_current_systems') + ',' + t('general_words.smart_lighting') + ',' + t('general_words.security_and_safety')}
            />
            <Tada>
                <div className='container'>
                    <div className='coming-soon__img'>
                        <LazyLoad height={200}>
                            <img src={comingSoonImg} title={t('general_words.coming_soon')} alt={t('general_words.coming_soon')} />
                        </LazyLoad>
                    </div>
                    <h1 className='h1 not-found__title'> {t('general_words.coming_soon')} </h1>
                </div>
            </Tada>
        </div>
    )
}

export default ComingSoon