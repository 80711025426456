import React from 'react'
import LazyLoad from 'react-lazyload';
import Flip from 'react-reveal';

const SolutionItem = ({title, desc, img, icon, direction = "left-bg"}) => {
  return (
    <div className="solutions-detailed-page__row">
        <Flip bottom>
            <div className={"container " + direction}>
                <h2 className={"h2 solutions-detailed-page__title d-flex align-items-center main-before-icon " + icon}> {title} </h2>
                <p className="solutions-detailed-page__desc">
                    {desc}
                </p>
            </div>
        </Flip>
        <Flip bottom>
            <div className="solutions-detailed-page__banner">
                <LazyLoad height={200}>
                    <img src={require('../../assets/images/global/' + img)} alt={title} title={title} />
                </LazyLoad>
            </div>
        </Flip>
    </div>
  )
}

export default SolutionItem