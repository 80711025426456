import React from 'react';

// Translation
import { useTranslation } from 'react-i18next';

// Components
import Header from './sections/Header';
import AboutSmartHome from './sections/AboutSmartHome';
import WhoWeAre from './sections/WhoWeAre';
import OurSolutions from './sections/OurSolutions';
import WorldNumbers from './sections/WorldNumbers';
import SEO from './../SEO/SEO';

const Homepage = () => {

  const { t } = useTranslation();
  
  return (
    <>
        <SEO
            title={t('general_words.home') + ' | ' + t('about_us.main_desc').substring(0, 40)}
            description={t('about_us.main_desc').substring(0, 200)}
            name={t('general_words.lojiatech')}
            type='article'
            image={window.location.host + require('../../assets/images/icons/lojiatech-logo.png')}
            keywords={t('general_words.smart_home_system') + ',' + t('general_words.smart_building_systems') + ',' + t('general_words.light_current_systems') + ',' + t('general_words.smart_lighting') + ',' + t('general_words.security_and_safety')}
        />
        <Header lojiatechText={t('general_words.lojiatech')} smartHomeText={t('.general_words.smart_home_system')} headerText={t('homepage.header.text')} />
        <AboutSmartHome title={t('homepage.smart_home.title')} desc={t('homepage.smart_home.desc')} />
        <WhoWeAre title={t('homepage.who_we_are.title')} desc={t('homepage.who_we_are.desc')} ctaLink={t('website_links.about_us')} showMore={t('general_words.show_more')} />
        <OurSolutions title={t('homepage.our_solution.title')}  ourSolutionLink={t('website_links.our_solutions')} showMore={t('general_words.show_more')} smartHomeSystem={t('general_words.smart_home_system')} smartBuildingSystems={t('general_words.smart_building_systems')} lightCurrentSystems={t('general_words.light_current_systems')} />
        <WorldNumbers title={t('homepage.numbers.title')} million={t('homepage.numbers.million')} usd={t('homepage.numbers.usd')} smartHomeNumbers={t('homepage.numbers.smart_home_numbers')} smartHomeSize={t('homepage.numbers.smart_home_size')} />
    </>
  )
}

export default Homepage