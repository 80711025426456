import React from 'react';

// LazyLoad
import LazyLoad from 'react-lazyload';

// Images
import desktopEnImg from '../../../assets/images/global/header-img.jpg';
import mobileEnImg from '../../../assets/images/global/header-img-mobile.png';
import desktopArImg from '../../../assets/images/global/header-img-ar.jpg';
import mobileArImg from '../../../assets/images/global/header-img-mobile-ar.jpg';
import smartHomeIcons from '../../../assets/images/icons/Smart home icons.svg';

import Zoom from 'react-reveal/Zoom';

const Header = ({ lojiatechText, smartHomeText, headerText }) => {

    const selectedLang = localStorage.getItem("i18nextLng") || "ar";

    const showImagesBasedOnLang = () => {
        if (selectedLang === "en-US") {
            return (
                <LazyLoad height={200}>
                    <img src={desktopEnImg} className="d-none d-lg-inline" alt={ lojiatechText } title={ lojiatechText } />
                    <img src={mobileEnImg} className="d-lg-none" alt={ lojiatechText } title={ lojiatechText } />
                </LazyLoad>
            )
        } else {
            return (
                <LazyLoad height={200}>
                    <img src={desktopArImg} className="d-none d-lg-inline" alt={ lojiatechText } title={ lojiatechText } />
                    <img src={mobileArImg} className="d-lg-none" alt={ lojiatechText } title={ lojiatechText } />
                </LazyLoad>
            )
        }
    }
    
  return (
    <section className="main-header">
        <div className="main-header__bg">
            {showImagesBasedOnLang()}
        </div>
        <div className="container">
            <div className="row">
                <Zoom>
                    <div className="col-12 col-lg-5 d-none d-lg-inline">
                        <div className="main-header__img">
                            <img src={smartHomeIcons} alt={ smartHomeText } title={ smartHomeText } />
                        </div>
                    </div>
                    <div className="col-12 col-lg-7 d-flex align-items-center">
                        <h1 className="h1 main-header__title" dangerouslySetInnerHTML={{__html: headerText}}></h1>
                    </div> 
                </Zoom>
            </div>
        </div>
    </section>
  )
}

export default Header