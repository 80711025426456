import React from 'react';
import { useTranslation } from 'react-i18next';
import MainTextBox from '../Shared/MainTextBox';
import OurMission from './sections/OurMission';
import OurValues from './sections/OurValues';
import OurVision from './sections/OurVision';
import SEO from './../SEO/SEO';

const AboutUsPage = () => {

    const { t } = useTranslation();

    return (
        <section className="about-us">
            <SEO
                title={t('general_words.about_us') + ' | ' + t('about_us.main_desc').substring(0, 40)}
                description={t('about_us.main_desc').substring(0, 200)}
                name={t('general_words.lojiatech')}
                type='article'
                image={window.location.host + require('../../assets/images/icons/lojiatech-logo.png')}
                keywords={t('general_words.smart_home_system') + ',' + t('general_words.smart_building_systems') + ',' + t('general_words.light_current_systems') + ',' + t('general_words.smart_lighting') + ',' + t('general_words.security_and_safety')}
            />
            <div className="container">
                <MainTextBox title={t('about_us.main_title')} desc1={t('about_us.main_desc')} firstItem="first-item" />
                <OurMission title={t('about_us.our_mission.title')} desc={t('about_us.our_mission.desc')} />
                <OurVision title={t('about_us.our_vision.title')} desc={t('about_us.our_vision.desc')} />
                <OurValues title={t('about_us.our_values.title')} items={t('about_us.our_values.items', { returnObjects: true })} />
            </div>
        </section>
    )
}

export default AboutUsPage