import React from 'react';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import notfoundImg from "../../assets/images/global/404.png";
import SEO from './../SEO/SEO';
import Jump from 'react-reveal/Jump';

const NotFound = () => {

    const { t } = useTranslation();

    return (
        <div className='not-found d-flex align-items-center justify-content-center text-center'>
            <SEO
                title={t('not_found.title') + ' | ' + t('about_us.main_desc').substring(0, 40)}
                description={t('about_us.main_desc').substring(0, 200)}
                name={t('general_words.lojiatech')}
                type='article'
                image={window.location.host + require('../../assets/images/icons/lojiatech-logo.png')}
                keywords={t('general_words.smart_home_system') + ',' + t('general_words.smart_building_systems') + ',' + t('general_words.light_current_systems') + ',' + t('general_words.smart_lighting') + ',' + t('general_words.security_and_safety')}
            />
            <Jump>
            <div className='container'>
                <div className='not-found__img'>
                    <LazyLoad height={200}>
                        <img src={notfoundImg} title={t('not_found.title')} alt={t('not_found.title')} />
                    </LazyLoad>
                </div>
                <h1 className='h1 not-found__title'> {t('not_found.title')} </h1>
                <p className='not-found__desc'> {t('not_found.desc')} </p>
            </div>
            </Jump>
        </div>
    )
}

export default NotFound