import React from 'react'
import { useTranslation } from 'react-i18next';

import Form from './sections/Form'
import Info from './sections/Info'
import SEO from './../SEO/SEO';
import Flip from 'react-reveal';

const ContactUs = () => {
    
    const { t } = useTranslation();

    return (
        <section className="contact-us">
            <SEO
                title={t('contact_us.title') + ' | ' + t('about_us.main_desc').substring(0, 40)}
                description={t('about_us.main_desc').substring(0, 200)}
                name={t('general_words.lojiatech')}
                type='article'
                image={window.location.host + require('../../assets/images/icons/lojiatech-logo.png')}
                keywords={t('general_words.smart_home_system') + ',' + t('general_words.smart_building_systems') + ',' + t('general_words.light_current_systems') + ',' + t('general_words.smart_lighting') + ',' + t('general_words.security_and_safety')}
            />
            <div className="container">
                <div className="row d-flex align-items-center justify-content-center">
                    <Flip bottom>
                        <div className="col-12 col-lg-6">
                            <Form title={t('contact_us.title')} thanks={t('contact_us.thanks')} ok={t('general_words.ok')} touchSoon={t('contact_us.touch_soon')} mobileNumber={t('contact_us.mobile_number')} firstName={t('contact_us.first_name')} familyName={t('contact_us.family_name')} emailWord={t('general_words.email')} sendWord={t('contact_us.send')} message={t('contact_us.message')} />
                        </div> 
                        <div className="col-12 col-lg-6">
                            <Info title={t('contact_us.title')} mainAddress={t('contact_us.main_address')} subAddress={t('contact_us.sub_address')} phone1={t('contact_us.phone1')} phone2={t('contact_us.phone2')} office={t('general_words.office')} location={t('general_words.location')} phone={t('general_words.phone')} />
                        </div> 
                    </Flip>
                </div> 
            </div>
        </section>
    )
}

export default ContactUs