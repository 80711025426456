import React from 'react';
import { useTranslation } from 'react-i18next';
import MainHeading from '../Shared/MainHeading';
import MainTextBox from '../Shared/MainTextBox';
import SolutionItem from '../Shared/SolutionItem';
import SEO from './../SEO/SEO';

const SmartHomePage = () => {

    const { t } = useTranslation();

    return (
        <>
            <SEO
                title={t('smart_home_page.title') + ' | ' + t('about_us.main_desc').substring(0, 40)}
                description={t('about_us.main_desc').substring(0, 200)}
                name={t('general_words.lojiatech')}
                type='article'
                image={window.location.host + require('../../assets/images/icons/lojiatech-logo.png')}
                keywords={t('general_words.smart_home_system') + ',' + t('general_words.smart_building_systems') + ',' + t('general_words.light_current_systems') + ',' + t('general_words.smart_lighting') + ',' + t('general_words.security_and_safety')}
            />
            <MainHeading title={t('solutions_page.main_title')} />
            <MainTextBox title={t('smart_home_page.title')} />
            <section className="solutions-detailed-page">
                {t('smart_home_page.items', { returnObjects: true }).map((el, index) => <SolutionItem key={index} title={el.title} desc={el.desc} img={el.img} icon={el.icon} direction={(index % 2 === 1) ? "right-bg" : "left-bg"} />)}
            </section>
        </>
    )
}

export default SmartHomePage