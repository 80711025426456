import React from 'react';

// LazyLoad
import LazyLoad from 'react-lazyload';

// Images
import smartHomeEnImg from "../../../assets/images/global/about-smart-home.svg";
import smartHomeArImg from "../../../assets/images/global/about-smart-home-ar.png";

import Flip from 'react-reveal';

const AboutSmartHome = ({ title, desc }) => {

    const selectedLang = localStorage.getItem("i18nextLng") || "ar";

    return (
        <section className="about-smart-home">
            <span className="triangle-shape triangle-left"></span>
            <Flip bottom>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <div className="about-smart-home__info">
                                <h3 className="h3"> {title} </h3>
                                <p> {desc} </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="about-smart-home__img">
                            <LazyLoad height={200}>
                                <img src={selectedLang === "en-US" ? smartHomeEnImg : smartHomeArImg} alt={title} title={title} />
                            </LazyLoad>
                            </div>
                        </div>
                    </div>
                </div>
            </Flip>
            <span className="triangle-shape triangle-right"></span>
        </section>
    )
}

export default AboutSmartHome