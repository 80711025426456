import React from 'react'
import visionEnImg from '../../../assets/images/global/Our Vision.png';
import visionArImg from '../../../assets/images/global/Our Vision-ar.png';
import LazyLoad from 'react-lazyload';
import Flip from 'react-reveal';

const OurVision = ({title, desc}) => {
    
    const selectedLang = localStorage.getItem("i18nextLng") || "ar";
    
    return (
        <div className="row left-img">
            <Flip bottom>
            <div className="col-12 col-lg-3">
                <div className="about-us__img">
                    <LazyLoad height={200}>
                        <img src={selectedLang === "en-US" ? visionEnImg : visionArImg} alt={title} title={title} />
                    </LazyLoad>
                </div>
            </div> 
            <div className="col-12 col-lg-9">
                <div className="about-us__info">
                    <h2 className="h2 about-us__title"> {title} </h2>
                    <p className="about-us__desc">
                        {desc}
                    </p>
                </div>
            </div> 
            </Flip>
        </div> 
    )
}

export default OurVision