import React from 'react'
import { Link } from 'react-router-dom';
import LightCurrentImg from "../../../assets/images/global/light-current-system.png"
import LazyLoad from 'react-lazyload';
import Flip from 'react-reveal';

const LightCurrent = ({title, desc, showMore, lightCurrentLink, icons = []}) => {
  return (
    <div className="row left-bg">
        <Flip bottom>
          <div className="col-12 col-lg-7">
              <div className="solutions-landing__info">
                  <h3 className="h3 solutions-landing__title"> {title} </h3>
                  <p className="solutions-landing__desc"> {desc} </p>
                  <ul className="list-unstyled p-0 solutions-landing__icons d-flex">
                    {icons.map((el, index) => <li key={index} className={"main-icon " + el}></li>)}
                  </ul>
                  <div className="solutions-landing__cta">
                    <Link to={lightCurrentLink} title={title} className="main-btn"> {showMore} </Link>
                  </div>
              </div>
          </div>
          <div className="col-12 col-lg-5">
              <div className="solutions-landing__img text-center">
                <LazyLoad height={200}>
                    <img src={LightCurrentImg} alt={title} title={title} />
                </LazyLoad>
              </div>
          </div>
        </Flip>
    </div>
  )
}

export default LightCurrent