import React from 'react'
import Flip from 'react-reveal';

const WorldNumbers = ({ title, million, usd, smartHomeNumbers, smartHomeSize }) => {
  return (
    <section className="world-numbers">
        <Flip bottom>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-lg-4">
                        <h2 className="h2 world-numbers__title" dangerouslySetInnerHTML={{__html: title}}></h2>
                    </div> 
                    <div className="col-6 col-lg-4">
                        <div className="world-numbers__wrapper text-center">
                            <div className="world-numbers__progress progress-one d-flex align-items-center justify-content-center">
                                <span> <span className="countup" data-value="258.54" data-is-decimal="true"> 258.54 </span> {million} </span>
                            </div>
                            <p className="world-numbers__label">
                                {smartHomeNumbers}
                            </p>
                        </div>
                    </div> 
                    <div className="col-6 col-lg-4">
                        <div className="world-numbers__wrapper text-center">
                            <div className="world-numbers__progress progress-two d-flex align-items-center justify-content-center">
                                <span> <span className="countup" data-value="99.41" data-is-decimal="true"> 99.41 </span> {usd} </span>
                            </div>
                            <p className="world-numbers__label">
                                {smartHomeSize}
                            </p>
                        </div>
                    </div> 
                </div> 
            </div>
        </Flip>
    </section>
  )
}

export default WorldNumbers