import React from 'react';

import LocationIcon from "../../../assets/images/icons/Contact us - Location icon.png";
import PhoneIcon from "../../../assets/images/icons/Contact us - Phone icon.png";
import LazyLoad from 'react-lazyload';

const Info = ({title, mainAddress, subAddress, phone1, phone2, location, phone, office}) => {
  return (
    <div className="contact-us__info text-center">
        <h1 className="h1 contact-us__title d-none d-lg-block"> {title} </h1>
        <div className="row">
            <div className="col-12 col-md-6">
                <div className="contact-us__icon">
                <LazyLoad height={200}>
                    <img src={LocationIcon} alt={location} title={location} />
                </LazyLoad>
                </div>
                <h4 className="h4 contact-us__label"> {mainAddress} </h4>
                <p className="contact-us__label-content"> {subAddress} </p>
            </div>
            <div className="col-12 col-md-6">
                <div className="contact-us__icon">
                    <LazyLoad height={200}>
                        <img src={PhoneIcon} alt={phone} title={phone} />
                    </LazyLoad>
                </div>
                <h4 className="h4 contact-us__label"> {office} </h4>
                <a href={"tel:" + phone1} className="contact-us__label-content d-block" title={phone1}> {phone1} </a>
                <a href={"tel:" + phone2} className="contact-us__label-content d-block" title={phone2}> {phone2} </a>
            </div>
        </div>
    </div>
  )
}

export default Info