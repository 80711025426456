import React, { useState } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

// const FORM_ENDPOINT = "https://public.herotofu.com/v1/336b0690-6440-11ed-891b-4f350712a1f0";
const FORM_ENDPOINT = "https://public.herotofu.com/v1/dc67fc60-651e-11ed-891b-4f350712a1f0";




const Form = ({ title, firstName, familyName, emailWord, sendWord, message,mobileNumber, thanks, touchSoon, ok }) => {
  
  const [submitted, setSubmitted] = useState(false);
  const MySwal = withReactContent(Swal)

  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };
  
  if (submitted) {
    document.getElementById("contact-form").reset();
    MySwal.fire({
      title: <strong> { thanks } </strong>,
      html: <i> { touchSoon } </i>,
      icon: 'success',
      confirmButtonText: ok,
      confirmButtonColor: '#003142',
    });
  }


  return (
    <form id="contact-form" action={FORM_ENDPOINT} onSubmit={handleSubmit} method="POST" target="_blank">
      <h1 className="h1 contact-us__title d-block d-lg-none">{title} </h1>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="form-group">
            <input
              type="text"
              name="first-name"
              className="form-control"
              autoComplete="off"
              placeholder={firstName}
              required
            />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="form-group">
            <input
              type="text"
              name="family-name"
              className="form-control"
              autoComplete="off"
              placeholder={familyName}
              required
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <input
          type="email"
          name="email"
          className="form-control"
          autoComplete="off"
          placeholder={emailWord}
          required
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          name="mobile-number"
          className="form-control"
          autoComplete="off"
          placeholder={mobileNumber}
          required
        />
      </div>
      <div className="form-group">
        <textarea
          name="message"
          className="form-control"
          rows="10"
          autoComplete="off"
          placeholder={message}
          required
        ></textarea>
      </div>
      <div className="form-group not-hovered">
        <input type="submit" className="main-btn" value={sendWord} />
      </div>
    </form>
  );
};

export default Form;
