import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// Translation
import './i18nextConf'; 

// Javascript Files
import './assets/js/bootstrap.bundle.min.js';
import './assets/js/script.js';

// CSS Files
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);