import React from 'react'
import Flip from 'react-reveal';

const MainTextBox = ({ title, desc1 = '', desc2 = '', firstItem = '' }) => {
  return (
    <Flip bottom>
      <section className={"main-text-box text-center " + firstItem}>
          <div className="container d-flex justify-content-center align-items-center flex-column">
              {
                firstItem !== '' ? 
                <h1 className="h1 main-text-box__title">
                    { title }
                </h1>
                :
                <h2 className="h2 main-text-box__title">
                    { title }
                </h2>
              }
              
              { desc1 !== '' && <p className="main-text-box__desc"> {desc1} </p>}
              { desc2 !== '' && <p className="main-text-box__desc"> {desc2} </p>}
          </div>
      </section> 
    </Flip>
    
  )
}

export default MainTextBox