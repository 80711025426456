import React from 'react';
// Lazy Load Plugin
import LazyLoad from "react-lazyload";

// Translation
import { useTranslation } from 'react-i18next';

// Shared Images
import whiteLogo from '../../assets/images/icons/white-lojiatech-logo.png';
import FacebookIcon from '../../assets/images/icons/Facebook.png';
import TwetterIcon from '../../assets/images/icons/Twitter.png';
import InstaIcon from '../../assets/images/icons/Instgram.png';
import LinkedInIcon from '../../assets/images/icons/Linkedin.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    
    const { t } = useTranslation();


    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="footer__info">
                            <div className="footer__info-logo"> 
                            <LazyLoad height={200}>
                                <img src={whiteLogo} title={t('general_words.lojiatech')} alt={t('general_words.lojiatech')} />
                            </LazyLoad>
                            </div>
                            <div className="footer__info-field">
                                <h5 className="h5"> {t('general_words.office')} </h5>
                                <Link href={"tel:" + t('contact_us.phone1')} title={t('contact_us.phone1')}> {t('contact_us.phone1')} </Link>
                                -
                                <Link href={"tel:" + t('contact_us.phone2')} title={t('contact_us.phone2')}> {t('contact_us.phone2')} </Link>
                            </div>
                            <div className="footer__info-field">
                                <h5 className="h5"> {t('general_words.email')} </h5>
                                <Link href={"mailto:" + t('contact_us.email')} title={t('contact_us.email')}> {t('contact_us.email')} </Link>
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-lg-flex col-lg-4 justify-content-center">
                        <div className="footer__links">
                            <h4 className="h4 footer__links-title"> { t('general_words.our_solutions') } </h4>
                            <ul className="list-unstyled m-0 p-0 footer__links-list">
                                <li>
                                    <Link to={ t('website_links.smart_home') } title={ t('general_words.smart_home_system') }> { t('general_words.smart_home_system') } </Link>
                                </li>
                                <li>
                                    <Link to={ t('website_links.smart_building') } title={ t('general_words.smart_building_systems') }> { t('general_words.smart_building_systems') } </Link>
                                </li>
                                <li>
                                    <Link to={ t('website_links.light_current') } title={ t('general_words.light_current_systems') }> { t('general_words.light_current_systems') } </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-none d-lg-flex col-lg-4 justify-content-end">
                        <div className="footer__links">
                            <h4 className="h4 footer__links-title"> { t('general_words.our_company') } </h4>
                            <ul className="list-unstyled m-0 p-0 footer__links-list">
                                <li>
                                    <Link to={ t('website_links.about_us') } title={ t('general_words.about_us') }> { t('general_words.about_us') } </Link>
                                </li>
                                <li>
                                    <Link to={ t('website_links.blog') } title={ t('general_words.blog') }> { t('general_words.blog') } </Link>
                                </li>
                                <li>
                                    <Link to={ t('website_links.contact_us') } title={ t('general_words.contact_us') }> { t('general_words.contact_us') } </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="footer__address">
                            <h3 className="h3 footer__address-base"> {t('contact_us.main_address')} </h3>
                            <p className="footer__address-details"> {t('contact_us.sub_address')} </p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <ul className="list-unstyled m-0 p-0 d-flex align-items-center justify-content-end footer__social-media">
                            <li>
                                <a href={t('contact_us.facebook_link')} title={t('contact_us.facebook')} target="_blank" rel="noreferrer">
                                    <LazyLoad height={200}>
                                        <img src={FacebookIcon} alt={t('contact_us.facebook')} title={t('contact_us.facebook')} />
                                    </LazyLoad>
                                </a>
                            </li>
                            <li>
                                <a href={t('contact_us.instagram_link')} title={t('contact_us.instagram')} target="_blank" rel="noreferrer">
                                    <LazyLoad height={200}>
                                        <img src={InstaIcon} alt={t('contact_us.instagram')} title={t('contact_us.instagram')} />
                                    </LazyLoad>
                                </a>
                            </li>
                            <li>
                                <a href={t('contact_us.twitter_link')} title={t('contact_us.twitter')} target="_blank" rel="noreferrer">
                                    <LazyLoad height={200}>
                                        <img src={TwetterIcon} alt={t('contact_us.twitter')} title={t('contact_us.twitter')} />
                                    </LazyLoad>
                                </a>
                            </li>
                            <li>
                                <a href={t('contact_us.linkedin_link')} title={t('contact_us.linkedin')} target="_blank" rel="noreferrer">
                                    <LazyLoad height={200}>
                                        <img src={LinkedInIcon} alt={t('contact_us.linkedin')} title={t('contact_us.linkedin')} />
                                    </LazyLoad>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> 
            </div>
        </footer>
    )
}

export default Footer