import React, { useEffect } from 'react';

// LazyLoad Plugin
import LazyLoad from "react-lazyload";

// Translation
import i18next from "i18next";
import { useTranslation } from 'react-i18next';

// Images
import MainLogo from '../../assets/images/icons/lojiatech-logo.png';
import { Link, NavLink } from 'react-router-dom';

const languageMap = {
    "en-US": { label: "English", direction: "ltr", active: true },
    "ar": { label: "العربية", direction: "rtl", active: false }
};

const Navbar = () => {
    const selectedLang = localStorage.getItem("i18nextLng") || "en-US";
    const { t } = useTranslation();

    // Change the lang and dir attributes
    useEffect(() => {
            try {
                document.documentElement.dir = languageMap[selectedLang].direction;
                document.documentElement.lang = selectedLang;
            } catch (error) {
                console.log(error)
            }
            // if (selectedLang !== "en-US") {
            //     require('../../assets/css/style-rtl.css')
            // }
    }, [selectedLang]);

    // Change the Language
    const changeLang = (lang) => {
        i18next.changeLanguage(lang)
    }

    const handleNavClick = () => {
        document.getElementById("navbar-toggler-id").click()
    }

    return (
        <nav className="navbar navbar-expand-lg fixed-top">
            <div className="container">
                <Link className="navbar-brand" to={t('website_links.homepage')} title={t('general_words.lojiatech')}>
                    <LazyLoad height={200}>
                        <img src={MainLogo} alt={t('general_words.lojiatech')} title={t('general_words.lojiatech')} />
                    </LazyLoad>
                </Link>
                <button className="navbar-toggler" id='navbar-toggler-id' type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink onClick={handleNavClick} className="nav-link" aria-current="page" to={t('website_links.homepage')} title={t('general_words.home')}> { t('general_words.home') } </NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" title={t('general_words.our_solutions')} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                {t('general_words.our_solutions')} 
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                <li><NavLink onClick={handleNavClick} className="dropdown-item parent-item" to={t('website_links.our_solutions')} title={t('general_words.our_solutions')}> {t('general_words.our_solutions')} </NavLink></li>
                                <li><NavLink onClick={handleNavClick} className="dropdown-item" to={t('website_links.smart_home')} title={t('general_words.smart_home_system')}> {t('general_words.smart_home_system')} </NavLink></li>
                                <li><NavLink onClick={handleNavClick} className="dropdown-item" to={t('website_links.smart_building')} title={t('general_words.smart_building_systems')}> {t('general_words.smart_building_systems')} </NavLink></li>
                                <li><NavLink onClick={handleNavClick} className="dropdown-item" to={t('website_links.light_current')} title={t('general_words.light_current_systems')}> {t('general_words.light_current_systems')} </NavLink></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <NavLink onClick={handleNavClick} className="nav-link" to={t('website_links.about_us')} title={ t('general_words.about_us') }> { t('general_words.about_us') } </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink onClick={handleNavClick} className="nav-link" to={t('website_links.blog')} title={ t('general_words.blog') }> { t('general_words.blog') } </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink onClick={handleNavClick} className="nav-link" to={t('website_links.contact_us')} title={ t('general_words.contact_us') }> { t('general_words.contact_us') } </NavLink>
                        </li>
                    </ul>
                </div>
                <div className='switch-language'>
                    { selectedLang === "en-US" ? 
                    <button className='' onClick={() => {
                        changeLang('ar');
                        window.location.reload();
                    }}> العربية </button>
                    :
                    <button className='' onClick={() => {
                        changeLang('en-US');
                        window.location.reload();
                    }}> English </button>
                    }
                </div>
            </div>
        </nav>
    )
}

export default Navbar