import React from 'react'
import { Link } from 'react-router-dom'
import Flip from 'react-reveal';

const WhoWeAre = ({ title, desc, ctaLink, showMore }) => {
  return (
    <section className="who-we-are text-center">
    <Flip bottom>
      <div className="container">
          <h2 className="h2 who-we-are__title"> { title } </h2>
          <p className="who-we-are__desc"> {desc} </p>
          <div className="who-we-are__cta">
              <Link to={ctaLink} title={ title } className="main-btn"> {showMore} </Link>
          </div>
      </div>
    </Flip>
    </section>
  )
}

export default WhoWeAre