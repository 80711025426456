import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import ComingSoon from '../ComingSoon/ComingSoon';

const SmartBuildingSystem = () => {

    const { t } = useTranslation();

    return (
        <>
            <ComingSoon />
        </>
    )
}

export default SmartBuildingSystem