import React from 'react'
import { useTranslation } from 'react-i18next';

import MainTextBox from '../Shared/MainTextBox';
import MainHeading from './../Shared/MainHeading';
import SmartHome from './sections/SmartHome';
import SmartBuilding from './sections/SmartBuilding';
import LightCurrent from './sections/LightCurrent';
import SEO from './../SEO/SEO';

const OurSolutions = () => {

    const { t } = useTranslation();

    return (
        <>
            <SEO
                title={t('solutions_page.main_title') + ' | ' + t('about_us.main_desc').substring(0, 40)}
                description={t('about_us.main_desc').substring(0, 200)}
                name={t('general_words.lojiatech')}
                type='article'
                image={window.location.host + require('../../assets/images/icons/lojiatech-logo.png')}
                keywords={t('general_words.smart_home_system') + ',' + t('general_words.smart_building_systems') + ',' + t('general_words.light_current_systems') + ',' + t('general_words.smart_lighting') + ',' + t('general_words.security_and_safety')}
            />
            <MainHeading title={t('solutions_page.main_title')} />
            <MainTextBox title={t('solutions_page.box_title')} desc1={t('solutions_page.box_desc.desc1')} desc2={t('solutions_page.box_desc.desc2')} />
            <section className="solutions-landing">
                <div className="container">
                    <SmartHome title={t('solutions_page.smart_home.title')} desc={t('solutions_page.smart_home.desc')} showMore={t('general_words.show_more')} smartHomeLink={t('website_links.smart_home')} icons={["lamp", "AC", "sound", "lock", "camera", "fire"]} />
                    <SmartBuilding title={t('solutions_page.smart_building.title')} desc={t('solutions_page.smart_building.desc')} showMore={t('general_words.show_more')} smartBuildingLink={t('website_links.smart_building')} icons={["lamp", "AC", "elevator", "lock", "camera", "fire"]} />
                    <LightCurrent title={t('solutions_page.light_current.title')} desc={t('solutions_page.light_current.desc')} showMore={t('general_words.show_more')} lightCurrentLink={t('website_links.light_current')} icons={["remote", "db", "camera", "lock", "fire"]} />
                </div>
            </section>
        </>
    )
}

export default OurSolutions