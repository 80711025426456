import React from 'react'
import { Link } from 'react-router-dom';

// Images
import OurSolutionIm from "../../../assets/images/global/homepage-our-solutions.png";

import Flip from 'react-reveal';

const OurSolutions = ({title, lightCurrentSystems, smartBuildingSystems, showMore, smartHomeSystem, ourSolutionLink }) => {
  return (
    <section className="our-solutions-section">
        <Flip bottom>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-6">
                        <div className="our-solutions-section__data text-center">
                            <h2 className="h2 our-solutions-section__title">{title}</h2>
                            <ul className="list-unstyled our-solutions-section__links p-0">
                                <li> {smartHomeSystem} </li>
                                <li> {smartBuildingSystems} </li>
                                <li> {lightCurrentSystems} </li>
                            </ul>
                            <div className="our-solutions-section__cta">
                                <Link to={ourSolutionLink} className="main-btn" title={title}> {showMore} </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="our-solutions-section__img text-center">
                            <img src={OurSolutionIm} alt={title} title={title} />
                        </div>
                    </div> 
                </div> 
            </div>
        </Flip>
    </section>
  )
}

export default OurSolutions