import React from 'react'
import Flip from 'react-reveal';

const MainHeading = ({ title }) => {
  return (
      <section className="main-heading">
            <div className="container">
            <Flip bottom>
                <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                        <h1 className="h1 main-heading__text"> { title } </h1>
                    </div> 
                    <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                        <ul className="list-unstyled p-0 main-heading__icons d-flex">
                            <li className="main-icon home"></li>
                            <li className="main-icon wifi-building"></li>
                            <li className="main-icon electric"></li>
                        </ul>
                    </div> 
                </div>
            </Flip>
            </div>
    </section>
  )
}

export default MainHeading