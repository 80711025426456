import React from 'react'
import Flip from 'react-reveal';

const OurValues = ({title, items}) => {
  return (
    <div className="about-us__values text-center">
        <Flip bottom>
        <h2 className="h2 about-us__values-title"> {title} </h2>
        <div className="row justify-content-center">
            {
                items.map((el, index) => {
                    if (index < 3) {
                        return (
                            <div key={index} className="col-12- col-lg-4">
                                <div className="about-us__values-wrapper">
                                    <div className={"about-us__values-icon main-icon " + el.icon}></div>
                                    <h4 className="h4 about-us__values-label"> {el.title} </h4>
                                    <p className="about-us__values-desc"> {el.desc} </p>
                                </div>
                            </div>  
                        )
                    }
                })
            }
        </div>
        </Flip>
        <Flip bottom>
        <div className="row justify-content-center align-items-start">
            {
                items.map((el, index) => {
                    if (index >= 3) {
                        return (
                            <div key={index} className="col-12- col-lg-4">
                                <div className="about-us__values-wrapper">
                                    <div className={"about-us__values-icon main-icon " + el.icon}></div>
                                    <h4 className="h4 about-us__values-label"> {el.title} </h4>
                                    <p className="about-us__values-desc"> {el.desc} </p>
                                </div>
                            </div>  
                        )
                    }
                })
            }
        </div>
        </Flip>
    </div>
  )
}

export default OurValues