import React from 'react'
import SmartBuildingImg from "../../../assets/images/global/smart-building-systems.png";
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import Flip from 'react-reveal';

const SmartBuilding = ({title, desc, showMore, smartBuildingLink, icons = []}) => {
  return (
    <div className="row right-bg">
    <Flip bottom>
        <div className="col-12 col-lg-5">
            <div className="solutions-landing__img text-center">
            <LazyLoad height={200}>
                <img src={SmartBuildingImg} alt={title} title={title} />
            </LazyLoad>
            </div>
        </div>
        <div className="col-12 col-lg-7">
            <div className="solutions-landing__info">
                <h3 className="h3 solutions-landing__title"> {title} </h3>
                <p className="solutions-landing__desc"> {desc} </p>
                <ul className="list-unstyled p-0 solutions-landing__icons d-flex">
                  {icons.map((el, index) => <li key={index} className={"main-icon " + el}></li>)}
                </ul>
                <div className="solutions-landing__cta">
                  <Link to={smartBuildingLink} title={title} className="main-btn"> {showMore} </Link>
                </div>
            </div>
        </div>
      </Flip>
    </div>
  )
}

export default SmartBuilding