/* START :: CountUp */
function animateValue(el, start = 0, end = 0, is_decimal = false, duration = 2000) {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;

    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    el.innerHTML = is_decimal
      ? prettyDecimal(Math.floor(progress * (end - start) + start))
      : prettyNum(Math.floor(progress * (end - start) + start))

    // if not at end, continue
    // if at end, return final number WITHOUT math operation to preserve decimals
    if (progress < 1) window.requestAnimationFrame(step);
    else el.innerHTML = is_decimal
      ? this.prettyDecimal(end)
      : this.prettyNum(end)
  };
  window.requestAnimationFrame(step);
}

function prettyNum(value = 0) {
  return value.toLocaleString('en-US'); 
}

function prettyDecimal(value = 0) {
  if (typeof value === 'string' && value.includes('$')) {
    value = this.numericCurrency(value);
  }

  // if 0, !Number is falsy and returns unformatted 0
  if (value == 0) return '0';

  // preserve string and exit
  if (!Number(value)) return value;

  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

// Fire the countUp code when the user reach to the world numbers section
let AreWereachedToNumbers = false;
window.addEventListener('scroll', function() {
  try {
    if (document.documentElement.scrollTop > (document.querySelector('.world-numbers').offsetTop - 400) && !AreWereachedToNumbers) {
        AreWereachedToNumbers = true;
        if (AreWereachedToNumbers) {
            document.querySelectorAll('.countup').forEach(el => {
                animateValue(el, 0, el.dataset.value, el.dataset.isDecimal);
            });
        }
    }
  } catch (error) {}
});


/* END :: CountUp */