import React from 'react';
import { useTranslation } from 'react-i18next';
import ComingSoon from '../ComingSoon/ComingSoon'
import SEO from './../SEO/SEO';

const Blog = () => {

  const { t } = useTranslation();

  return (
    <>
        <SEO
            title={t('general_words.blog') + ' | ' + t('about_us.main_desc').substring(0, 40)}
            description={t('about_us.main_desc').substring(0, 200)}
            name={t('general_words.lojiatech')}
            type='article'
            image={window.location.host + require('../../assets/images/icons/lojiatech-logo.png')}
            keywords={t('general_words.smart_home_system') + ',' + t('general_words.smart_building_systems') + ',' + t('general_words.light_current_systems') + ',' + t('general_words.smart_lighting') + ',' + t('general_words.security_and_safety')}
        />
        <ComingSoon />
    </>
  )
}

export default Blog